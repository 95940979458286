
import Dashboard from "views/Dashboard.js";
import LogoutProcess from "components/Authentication/Logout";
import FundsTable from "components/OneConstellation/Funds/FundsTable";
import FundDocumentsLayout from "layouts/OneConstellation/FundDocumentsLayout.js"
import Statistics from "components/OneConstellation/AppEngine/Statistics";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "tim-icons icon-atom",
  //   component: <Icons />,
  //   layout: "/admin",
  // },
  {
    path: "/funds",
    name: "OC - Funds",
    icon: "fa fa-money-check-alt",
    component: <FundsTable />,
    layout: "/admin",
    permissions: {
      VISIBILITY: ["SHOW_OC_FUNDS_TAB"]
    }
  },
  // {
  //   path: "/funddocuments",
  //   name: "OC - Fund Documents",
  //   icon: "fa fa-money-check-alt",
  //   component: <FundsDocuments />,
  //   layout: "/admin",
  // },

  {
    path: "/funddocuments",
    name: "OC - Fund Documents",
    icon: "fa fa-money-check-alt",
    component: <FundDocumentsLayout />,
    layout: "/admin",
    permissions: {
      VISIBILITY: ["SHOW_OC_FUNDS_TAB"]
    }
  },
  {
    path: "/appengine/statistics",
    name: "Test 123",
    icon: "fa fa-bomb",
    component: <Statistics />,
    layout: "/admin",
    permissions: {
      VISIBILITY: ["APP_ENGINE_STATISTICS"]
    }
  },
  // {
  //   path: "/map",
  //   name: "Map",
  //   icon: "tim-icons icon-pin",
  //   component: <Map />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "tim-icons icon-bell-55",
  //   component: <Notifications />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "tim-icons icon-single-02",
  //   component: <UserProfile />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: <TableList />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "tim-icons icon-align-center",
  //   component: <Typography />,
  //   layout: "/admin",
  // },
  {
    path: "/authentication/logout",
    name: "Logout",
    icon:  "fas fa-sign-out-alt",
    component: <LogoutProcess />,
    layout: "",
  },
];

export default routes;
