
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import LoginForm from "components/Authentication/LoginForm.js";
import { GoogleOAuthProvider } from '@react-oauth/google';

//import "./index.css";
import "assets/scss/black-dashboard-react.scss";
import "assets/css/app.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import LogoutProcess from "./components/Authentication/Logout";



const App = () => {
  const isAuthenticated = localStorage.getItem('Authorization');

  if (!isAuthenticated) {
    return (
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={ < LoginForm />} />
        </Routes>
      </BrowserRouter>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>)
  }

  return (

    <ThemeContextWrapper>

      <BackgroundColorWrapper>

        <GoogleOAuthProvider clientId="565812271801-kdfht0f7h0q4b6kfogfvofr95tjv7gdb.apps.googleusercontent.com" >
          <BrowserRouter>
            <Routes>

              {/* <Route
                    path="/"
                    element={<Dashboard/>}
                  /> */}
              <Route path="/authentication/login" element={<LoginForm />} />
              <Route path="/authentication/logout" element={<LogoutProcess />} />
              <Route path="/admin/*" element={<AdminLayout />} />
              <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
              {/* <Route path="*" element={<Navigate to="/admin/dashboard" replace />} /> */}
            </Routes>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>

  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
