import React, { useState } from "react";
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useLocation } from "react-router-dom";
import FundsDocuments from "components/OneConstellation/Documents/FundsDocuments.js";
import AssignDocumentsSets from "components/OneConstellation/Documents/AssignDocumentsSets.js";
import toast, { Toaster } from 'react-hot-toast';
import { Card, CardHeader, CardBody, Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Input, CardText, InputGroup, Label, FormGroup, ButtonGroup } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { useOneConstellationStateStore } from "../../store"

// Define your content components
const Content2 = () => <div className="content">Content for Link 2</div>;
const Content3 = () => <div className="content">Content for Link 3</div>;

const FundDocumentsLayout = () => {
  const { fundInfo } = useOneConstellationStateStore();
  const fundId = fundInfo.fundId;
  const fundName = fundInfo.fundName;
  const fundRegion = fundInfo.fundRegion;
  const [fundDocumentCurrentTab, setFundDocumentCurrentTab] = useState(0);
  const [countDocumentsAlreadySetToFund, setCountDocumentsAlreadySetToFund] = useState(0);
  const [hasAssignedDocuments, setHasAssignedDocuments] = useState(false);
  const [showRedirectionUrlToFundsPage, setshowRedirectionUrlToFundsPage] = useState(false);
  
  // Function to handle tab click
  const handleTabClick = (index) => {
    setFundDocumentCurrentTab(index);
  };

  React.useEffect(() => {
    if (fundId === null || fundId === undefined || fundId === "") {
      toast.error("Invalid fund id");
      setshowRedirectionUrlToFundsPage(true);
    }

  }, [])

  // Define a function to render content based on the selected tab
  const renderContent = () => {

    switch (fundDocumentCurrentTab) {
      case 0:
        return <FundsDocuments fundId={fundId} setHasAssignedDocuments={setHasAssignedDocuments} setFundDocumentCurrentTab={setFundDocumentCurrentTab} setCountDocumentsAlreadySetToFund={setCountDocumentsAlreadySetToFund}/>;
      case 1:
        return <AssignDocumentsSets fundId={fundId} countDocumentsAlreadySetToFund={countDocumentsAlreadySetToFund}/>;
      case 2:
        return <Content3 />;
      default:
        return null;
    }
  };

  return (
    <div className="content">
      {showRedirectionUrlToFundsPage ? (
        <Container fluid>
          <Card
            style={{
              width: '18rem',
              marginTop: '100px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            className="text-center"
          >
            <CardHeader>
              Error
            </CardHeader>
            <CardBody>
              <CardText>
                Invalid fund id. Please select <a href="/admin/funds">fund</a> and try again try again.
              </CardText>
            </CardBody>
          </Card>
        </Container>
      ) : (

        <>

        <h2>{fundId} | {fundName} - ({fundRegion})</h2>


          <Nav tabs className="mb-5 justify-content-center">
            <NavItem>
              <NavLink
                href="#"
                className={fundDocumentCurrentTab === 0 ? "active" : ""}
                onClick={() => handleTabClick(0)}
              >
                View Documents
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={fundDocumentCurrentTab === 1 ? "active" : ""}

                // disabled={hasAssignedDocuments}
                onClick={() => handleTabClick(1)}
              >
                Add Documents
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={fundDocumentCurrentTab === 2 ? "active" : ""}
                onClick={() => handleTabClick(2)}
              >
                Manage Documents
              </NavLink>
            </NavItem>
          </Nav>
        
          {renderContent()}
           
        </>
      )}
    </div>
  );
};

export default FundDocumentsLayout;
