import axios from "axios";

// Create an Axios instance with the base URL from environment variables
const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});

/**
 * @param ** @returns {Promise<AxiosResponse>}
 * 
 */
// Function to make an authenticated API call
export async function authenticatedAPI(eventName, url, config={}, descriptiveInfo = "") {
    return new Promise(async (resolve, reject) => {

        if( !['USER_ATTEMPT_TO_LOGIN','USER_LOGOUT','GET_USER_ROLE_PERMISSIONS'].includes(eventName) )
        {
            if((!localStorage.getItem("permissions") || !localStorage.getItem("userIdentifier")))
            {
                localStorage.removeItem("Authorization");
                window.location.href = "/authentication/login"
                resolve();
            }
        }

        const startTime = Date.now();
        const userIdentifier = JSON.parse(localStorage.getItem("userIdentifier")) ?? {};
        try {
                  
            const token = localStorage.getItem("Authorization");
            if (token) {
                config.headers = {
                    ...config.headers,
                    Authorization: `${token}`,
                };
            }
            const response = await api(url,config);

            if(config.data?.hasOwnProperty("password"))
            {
                delete config.data.password;
            }

            const telemetryData = {
                "event_name": eventName,
                "service": "archer",
                "source_identifier": "jarnet_archer",
                "status": true,
                "browser": {
                    "url": `${window.location.href}`,
                    "useragent": window.navigator.userAgent,
                    "userip": null,
                    "user_location": ""
                },
                "action": {
                    "type": token == null ? "GUEST_API_REQUEST" : "AUTHENTICATED_API_REQUEST",
                    "details": {
                        "api_tag": eventName,
                        "method": (config.method ?? "GET").toUpperCase(),
                        "url": `${process.env.REACT_APP_SERVER_URL}${url}`,
                        "params": config.params ?? {},
                        "body": config.body ?? {},
                        "headers": config.headers ?? {},
                        "http_code": response.status,
                        "response_identifier": ""
                    }
                },
                "actor": {
                    "type": "user",
                    "user": token !== null ? {...userIdentifier} : undefined
                },
                "descriptive_info":descriptiveInfo,
                // "file_details": {
                //     "file_name": "components/domains.tsx",
                //     "function_name": "getUsersDomains()"
                // },
                "log_severity": "INFO", 
                "time_in_msec": startTime,
                "time_out_msec": Date.now()
            };

            switch(eventName)
            {   
                case "USER_LOGOUT": 
                case "USER_ATTEMPT_TO_LOGIN": {
                   // const userIp = (await api("https://api.ipify.org/?format=text")).data
                    telemetryData.actor = { 
                        type: "user",
                        user: {
                            appUserEmail: config.data?.email ?? userIdentifier.appUserEmail ?? ""
                        }
                    }

               //     telemetryData.browser.userip = userIp;


                    await api(process.env.REACT_APP_TELEMETRY_URL,{
                        method: "POST",
                        data: telemetryData
                    });
                }
                break;
                default:
                    api(process.env.REACT_APP_TELEMETRY_URL,{
                        method: "POST",
                        data: telemetryData
                    });
            }
            

            resolve(response);

            
        } catch (error) {

            const token = localStorage.getItem("Authorization");
            
            const telemetryData = {
                "event_name": eventName,
                "service": "archer",
                "source_identifier": "jarnet_archer",
                "status": false,
                "browser": {
                    "url": `${window.location.href}`,
                    "useragent": window.navigator.userAgent,
                    "userip": null,
                    "user_location": ""
                },
                "action": {
                    "type": "AUTHENTICATED_API_REQUEST",
                    "details": {
                        "api_tag": eventName,
                        "method": (config.method ?? "GET").toUpperCase(),
                        "url": `${process.env.REACT_APP_SERVER_URL}${url}`,
                        "params": config.params ?? {},
                        "body": config.body ?? {},
                        "headers": config.headers ?? {},
                        "http_code": error.response?.status ?? 0,
                        "response_identifier": ""
                    }
                },
                "actor": {
                    "type": token ? "user" : "guest",
                    "user":{
                        "token":token ?? ""
                    }
                },
                // "file_details": {
                //     "file_name": "components/domains.tsx",
                //     "function_name": "getUsersDomains()"
                // },
               "api_response": {
                    "http_status_code": error.response?.status ?? 0,
                    "http_error_response": JSON.stringify(error.response?.data?.response ?? {} ),
                },
                "descriptive_info":descriptiveInfo,
                "log_severity": "ERROR",
                "time_in_msec": startTime,
                "time_out_msec": Date.now()
            };

            switch(eventName)
            {   
                case "USER_LOGOUT": 
                case "USER_ATTEMPT_TO_LOGIN": {
                    //const userIp = (await api("https://api.ipify.org/?format=text")).data
                    telemetryData.actor = { 
                        type: "user",
                        user: {
                            appUserEmail: config.data?.email ?? userIdentifier.appUserEmail ?? "",
                        }
                    }
                    //telemetryData.browser.userip = userIp;
                }
                break;
                default:
            }

            try {
                await api(process.env.REACT_APP_TELEMETRY_URL,{
                    method: "POST",
                    data: telemetryData
                });
            }
            catch(error)
            {
                console.log("kTelemetry Error Occured", error)
            }
           

            reject(error);
        }
    });
}

export async function ping() {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await authenticatedAPI("CLIENT_PING","/api/v1/users/authorization/ping");
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

api.authenticatedAPI = authenticatedAPI;
export default api;