import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { dateFilter, textFilter } from "react-bootstrap-table2-filter";
import { Card, CardHeader, CardBody, Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Input } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFile } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useOneConstellationStateStore } from "../../../store"
import { authenticatedAPI } from "network/api";
export default function FundsTable() {
  const navigate = useNavigate();
  const [fundsData, setFundsData] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [modal, setModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [textAreaData, setTextAreaData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [isFundInfoSet, setIsFundInfoSet] = useState(false);
  const toggle = () => setModal(!modal);
  const { setFundInfo } = useOneConstellationStateStore();

  // const redirectToDocumentManagement = (fundId) => {
  //   return <Navigate to= state={{ fundId: fundId }} />;
  // }
  const handleModalData = (row) => {
    toggle();
    setRowData(row)
    setTextAreaData(row.description);
  }
  const loadTableData = () => {
    setLoading(true);
    authenticatedAPI("GET_ALL_FUNDS_LIST","/api/v1/oneconstellation/data/funds",{
      method: "get"
    }, `Fetch all funds list for user`).then((response) => {
        let data = response.data.response.map((item, index) => ({
          ...item,
          _id: item.id, // Add a unique key for each item
        }));
        data = data.filter((value) => value.deleted_at === null);
      
        // data.map(e=>{ e.action = <TableActions/>})
        setFundsData(data); // Assuming API response is an array of objects
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        if(error.status === 403)
          {
            toast.error(`You don't have permissions to get fund list.`, {
              duration: 3000, // Duration of the toast in milliseconds
              position: 'top-right', // Position of the toast on the screen
              // Other options...
            });
          }
          else {
            toast.error('Error on fetching fund list.', {
              duration: 3000, // Duration of the toast in milliseconds
              position: 'top-right', // Position of the toast on the screen
              // Other options...
            });
          }
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of error
      });
  }

  const handleOpenDocumentTypes =(rowData) => {
    const fundInfo = {
      fundId: rowData.id,
      fundName: rowData.name,
      fundRegion: rowData.region
    }
    setFundInfo(fundInfo)
    setIsFundInfoSet(true);
    navigate(`/admin/funddocuments`)
  }

  const handleSaveData = () => {
    setModalLoading(true);
    const fundId = rowData.id;
    const fundDesc = textAreaData;
    
    authenticatedAPI("UPDATE_FUND_DESCRIPTION","/api/v1/oneconstellation/data/funds/description",{
      method: "post",
      data: {
        fundId: fundId,
        description: fundDesc,
      },
    }, `Update fund description for fundId ${fundId}`).then((response) => {

        setModalLoading(false);
        toggle();
        loadTableData();
        console.log("response", response);
      })
      .catch((error) => {
        if(error.status === 403)
        {
          toast.error(`You don't have permissions to update fund description.`, {
            duration: 3000, // Duration of the toast in milliseconds
            position: 'top-right', // Position of the toast on the screen
            // Other options...
          });
        }
        else {
          toast.error('Document description update failed.', {
            duration: 3000, // Duration of the toast in milliseconds
            position: 'top-right', // Position of the toast on the screen
            // Other options...
          });
        }
        console.error("Error fetching data:", error);
        setModalLoading(false);
        // Set loading to false in case of error
      });



  }


  React.useEffect(() => {
    loadTableData();
  }, []);

  const columns = [
    {
      dataField: "id",
      text: "Id",
      
    },
    {
      dataField: "named_id",
      text: "Code",
    },
    {
      dataField: "name",
      text: "Name",
      filter: textFilter(),
    },
    {
      dataField: "administration_identity_id",
      text: "Entity Id",
      filter: textFilter(),
    },
    {
      dataField: "region",
      text: "Region",
      filter: textFilter(),
    },
    {
      dataField: "created_at",
      text: "Created at",
      filter: dateFilter({
        type: "date"
      }),
      formatter: (row, rowData) => {
        return rowData.created_at ? new Date(rowData.created_at).toLocaleDateString():"";
      }
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: (row, rowData) => {
        return (
          <div>
            <a onClick={() => handleModalData(rowData)} className="cursor-pointer" style={{ marginRight: '10px' }} key={rowData.id}>
              <FontAwesomeIcon icon={faEdit} />
            </a>

            <a onClick={() => handleOpenDocumentTypes(rowData)}
                className="cursor-pointer" style={{ marginRight: '10px' }}
                key={rowData.id + "qq"}>
              <FontAwesomeIcon icon={faFile} />
            </a>
          </div>
        )
      }
    }
  ];
  return (
   
    <div className="content">
      {modal && rowData && (
        <Modal isOpen={modal} toggle={toggle} key={rowData.id} size="xl" fade={true}>
          <ModalHeader toggle={toggle}>Description</ModalHeader>
          <ModalBody>
            {modalLoading ? (
              // Center the loading spinner within the modal body
              <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                <ThreeDots visible={true} height="40" width="40" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
              </div>
            ) : (
              // Use a regular textarea element for the modal body
              <textarea
                id={`fund_desc_${rowData.id}`}
                defaultValue={textAreaData}
                style={{ color: "black" }}
                className="form-control my-textarea"
                rows={10}
                onChange={(event) => setTextAreaData(event.target.value)}
              />
            )}
          </ModalBody>
          <ModalFooter>
            {!modalLoading && (
              <>
                {/* Adjust button styles */}
                <Button color="secondary" onClick={toggle} style={{ paddingLeft: '30px', paddingRight: '30px', marginRight: '10px' }}>
                  Cancel
                </Button>
                <Button color="primary" onClick={() => handleSaveData(rowData.id)} style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                  Save
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      )}


      <Container fluid>
        <Card>
          <CardHeader>
            <h5 className="title">One Constellation Funds</h5>
          </CardHeader>
          <CardBody>
            {loading ? (
              // Conditionally render loader if loading is true
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }}
              >
                <ThreeDots
                  visible={true}
                  height="40"
                  width="40"
                  color="#4fa94d"
                  radius="9"
                  ariaLabel="three-dots-loading"
                />
              </div>
            ) : (
              <BootstrapTable
                keyField="id"
                data={fundsData}
                columns={columns}
                pagination={paginationFactory({
                  //  sizePerPage: 32
                })}
                bootstrap4={true}
                striped
                hover
                condensed
                noDataIndication="No data available"
                defaultSorted={[{ dataField: "id", order: "asc" }]}
                filter={filterFactory()} // Enable table filtering
              />
            )}
          </CardBody>
        </Card>
      </Container>
            
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  );
}
