import React, { useEffect, useState } from 'react';
import { Container, Modal, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { ThreeDots } from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { authenticatedAPI } from 'network/api';
export const ChildDocusignConfigurationModal = ({ show, handleClose, parentDocumentData }) => {

  const [modalLoadingDocuSignConfiguration, setModalLoadingDocuSignConfiguration] = useState(false);
  const result = Object.keys(parentDocumentData.bucket_key ?? {}).map(key => ({
    url: parentDocumentData.bucket_key[key].url,
    title: parentDocumentData.bucket_key[key].title,
    doc_note: parentDocumentData.bucket_key[key].doc_note,
    docusign_template_id: parentDocumentData.bucket_key[key].docusign_template_id
  }));


  const [documents, setDocuments] = useState(result);

  const [newDocument, setNewDocument] = useState({
    title: '',
    doc_note: '',
    url: '',
    docusign_template_id: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDocument({ ...newDocument, [name]: value });
  };

  const handleAdd = () => {
    setDocuments([...documents, { ...newDocument, id: documents.length + 1 }]);
    setNewDocument({ title: '', doc_note: '', url: '', docusign_template_id: '' });
  };

  const handleDelete = (id) => {
    setDocuments(documents.filter(doc => doc.id !== id));
  };

  const generateJSON = () => {
    const jsonOutput = documents.reduce((acc, doc) => {
      acc[doc.title.toUpperCase()] = {
        url: doc.url,
        title: doc.title,
        docusign_template_id: doc.docusign_template_id,
        doc_note: doc.doc_note
      };
      return acc;
    }, {});
    return JSON.stringify(jsonOutput, null, 2);
  };

  useEffect(() => {
    
  },[]);

  return (
    <Container fluid>
      <Modal show={show} onHide={handleClose} className="modal-dialog-centered custom-modal-wide" style={{"transform": "none"}}>
        <Modal.Header closeButton className="custom-modal-header child-doc-modal">
          <Modal.Title className='child-doc-modal-title' style={{color: "#fff"}}>
            {parentDocumentData.entity_name} - {parentDocumentData.document_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="child-doc-modal child-doc-modal-body">
          {!modalLoadingDocuSignConfiguration ? (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Note</th>
                    <th>PDF</th>
                    <th>Template ID</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.length > 0 && documents.map(doc => (
                    <tr key={doc.id}>
                      <td>{doc.title}</td>
                      <td>{doc.doc_note}</td>
                      <td>{doc.url}</td>
                      <td>{doc.docusign_template_id}</td>
                      <td>
                        <a onClick={() => handleDelete(doc.id)} className="cursor-pointer" style={{ marginRight: '10px' }} key={doc.id + "delete"}>
                          <FontAwesomeIcon icon={faTrash} />
                        </a>
                      </td>
                    </tr>
                  ))}
                  <tr>
                  <td>
                      <Form.Control 
                        type="text" 
                        placeholder="Title" 
                        name="title" 
                        value={newDocument.title} 
                        onChange={handleChange} 
                      />
                    </td>
                    <td>
                      <Form.Control 
                        type="text" 
                        placeholder="Document Note" 
                        name="doc_note" 
                        value={newDocument.doc_note} 
                        onChange={handleChange} 
                      />
                    </td>
                    <td>
                      <Form.Control 
                        type="text" 
                        placeholder="Url" 
                        name="url" 
                        value={newDocument.url} 
                        onChange={handleChange} 
                      />
                    </td>
                    <td>
                      <Form.Control 
                        type="text" 
                        placeholder="Template Id" 
                        name="docusign_template_id" 
                        value={newDocument.docusign_template_id} 
                        onChange={handleChange} 
                      />
                    </td>
                    <td>
                      <Button onClick={handleAdd}>Add</Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Row>
                <Col>
                  <pre>{generateJSON()}</pre>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant="success" onClick={() => console.log('Push clicked')}>Save</Button>
                  <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Col>
              </Row>
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
              <ThreeDots visible={true} height="40" width="40" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer child-doc-modal">
         
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ChildDocusignConfigurationModal;
