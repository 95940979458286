export function getPermissions()
{
    const permissions = localStorage.getItem('permissions');
    if (permissions)
    {
        return JSON.parse(permissions);
    }
    return {};
}
export function hasVisibilityPermission(permission)
{
    const userPermissions = getPermissions();
    if(Array.isArray(permission))
    {
        return permission.every(p => hasVisibilityPermission(p));
    }

    if(userPermissions.VISIBILITY)
    {
        return userPermissions.VISIBILITY.includes(permission);
    }
    return false;
}
export function setPermissions(permissions)
{
    localStorage.setItem('permissions', JSON.stringify(permissions));
}
export function clearPermissions()
{
    localStorage.removeItem('permissions');
}
export function setPermission(permission, value)
{
    const permissions = getPermissions();
    permissions[permission] = value;
    setPermissions(permissions);
}
export function getPermissionsFromLocalStorage()
{
    const permissions = localStorage.getItem('permissions');
    if (permissions)
    {
        return JSON.parse(permissions);
    }
    return {};
}