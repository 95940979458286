import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faBookOpen, faTrash } from '@fortawesome/free-solid-svg-icons'
import toast, { Toaster } from 'react-hot-toast';
import { authenticatedAPI } from "../../../network/api";

export default function DocusignDocumentsPresetsBox({ fundId }) {


  const [loading, setLoading] = useState(true);
  const [docuSignOptions, setDocusignOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  

  useEffect(() => {
    
    if(fundId)
      {
        authenticatedAPI("GET_DOCUSIGN_DOCUMENTS_SETS","/api/v1/oneconstellation/data/docusign_documents_sets", {}, `Fetch Docusign presets for fund id: ${fundId}`)
        .then((response) => {
          setDocusignOptions(response.data.response);
          setLoading(false);
          
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
          toast.error('Document deletion failed', {
            duration: 3000, // Duration of the toast in milliseconds
            position: 'top-right', // Position of the toast on the screen
            // Other options...
          });
        });
      }
    
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdownSelect = (value) => {
    setSelectedOption(value);
    setDropdownOpen(false);
  };


  const handleAssignButtonClick = () => {
    setLoading(true);
    authenticatedAPI("ADD_DOCUSIGN_DOCUMENTS","/api/v1/oneconstellation/data/docusign_documents_sets",{
      method: "post",
      data: {
        fund_id: fundId,
        docusign_type: selectedOption
      }
    }, `Assign Docusign preset for fund id: ${fundId}`)
      .then((response) => {
        setLoading(false);
       console.log(response);
       toast.success('Document set assigned successfully', {
        duration: 3000, // Duration of the toast in milliseconds
        position: 'top-right', // Position of the toast on the screen
        // Other options...
      });
      })
      .catch((error) => {
        setLoading(false);
        toast.error('Document set assignment failed', {
          duration: 3000,
          position: 'top-right',
      
        })
        console.error("Error fetching data:", error);
        
      });
    // console.log("Selected option:", selectedOption);
  };

  return (
   
        <Card>
            <CardHeader className="text-center">
              <h5 className="title mb-0">DocuSign Documents Presets</h5>
            </CardHeader>
            <CardBody className="text-center">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                  <ThreeDots visible={true} height="40" width="40" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
                </div>
              ) : (
                <Card>
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="mb-3">
                    <DropdownToggle caret>
                      {selectedOption ? selectedOption : "Select an option"}
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {docuSignOptions.map((option) => (
                        <DropdownItem key={option + "__key"} onClick={() => handleDropdownSelect(option.split(".")[0])}>
                          {option.split(".")[0]}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  
                  <Button color="primary" className="w-100" onClick={handleAssignButtonClick} disabled={!selectedOption}>Assign</Button>
                </Card>
              )}
            </CardBody>
          </Card>
  );
}
