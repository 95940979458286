import React, { useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { ThreeDots } from 'react-loader-spinner';
import {authenticatedAPI} from './../../network/api';
import { encode as base64_encode} from 'base-64';
const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);



  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      const response = await authenticatedAPI("USER_ATTEMPT_TO_LOGIN",'/api/v1/users/authentication/login', {
        method: "post",
        headers: {
          "binary-encoded": true
        },
        data: {auth: base64_encode(JSON.stringify({
          email: email,
          password: password
        })).toString("base64")}
      }, `Attempting to login user: ${email} @ ${(new Date())}`);
     
      if(response.data.status === true) {
       
        const appToken = response.data.response.appToken;
        const userIdentifier = response.data.response.userIdentifier
        localStorage.setItem('Authorization', `Bearer ${appToken}`);
        localStorage.setItem('userIdentifier', JSON.stringify(userIdentifier));

        const rolespermissions = await authenticatedAPI("GET_USER_ROLE_PERMISSIONS", '/api/v1/users/authorization/rolespermissions',{},
          `Requesting Role Permissions for user ${email}`
        );

        if(rolespermissions.data.status === true) {

            localStorage.setItem("permissions", JSON.stringify(rolespermissions.data.response));
            window.location.href = '/admin/dashboard';

        } else {
           setError(rolespermissions.data.response);
        }

      }
      else {
        setError(response.data.response);
      }

    } catch (error) {
      console.log({error})
      setError(error?.response?.data?.response || 'An error occurred during login.');
    }
    finally {
      setIsLoading(false);
    }
  };

  return (


    <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
      <Card className="p-4" style={{ minWidth: '200px', maxWidth: '400px' }}>
        <CardBody className="text-center">
          <h1>Authentication</h1>
         
          {isLoading ? (
              // Center the loading spinner within the modal body
              <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                <ThreeDots visible={true} height="40" width="40" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
              </div>
            ) : (
              <>
               <p style={{ fontSize: '16px', color: 'gray', textAlign: 'center', paddingBottom: '10px', paddingTop: '10px' }}>Please login using credentials.</p>
              
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  minLength={8}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  minLength={8}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <Button type="submit" color="primary" className="w-100 mb-2" style={{ paddingTop: '10px', paddingBottom: '10px', marginTop: '10px' }}>
                Login
              </Button>
            </form>
            </>
          )}
          {error && <p style={{ color: 'red', paddingTop: '10px' }}>{error}</p>}
          <p style={{ fontSize: '12px', color: 'gray', textAlign: 'center', paddingTop: '10px' }}>For assistance, please email to <br /> support@one-constellation.com </p>
        </CardBody>
      </Card>
    </div>
  );
};

export default LoginForm;
