import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Row, Col } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import toast, { Toaster } from 'react-hot-toast';
import { authenticatedAPI } from "../../../network/api";
import CsvDownloader from 'react-csv-downloader';
export default function UserDocumentsPresetBox({ fundId }) {

  const [loading, setLoading] = useState(true);
  const [fundDocumentsOptions, setFundDocumentsOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const csvColumns = [
    {
      id: "TAG",
      displayName: 'TAG'
    },
    {
      id: "NAME",
      displayName: 'NAME'
    },
    {
      id: "ENTITY",
      displayName: 'ENTITY'
    },
    {
      id: "CATEGORY",
      displayName: 'CATEGORY'
    },
    {
      id: "DOCUMENT_NUMBER",
      displayName: 'DOCUMENT_NUMBER'
    },
    {
      id: "ISSUED_DATE",
      displayName: 'ISSUED_DATE'
    },
    {
      id: "EXPIRY_DATE",
      displayName: 'EXPIRY_DATE'
    },
    {
      id: "HAS_AMOUNT",
      displayName: 'HAS_AMOUNT'
    },
    {
      id: "TYPE_ID",
      displayName: 'TYPE_ID'
    },
  ]

  useEffect(() => {

    authenticatedAPI("GET_ALL_DOCUMENTS_PRESET_BY_REGION", "/api/v1/oneconstellation/data/fund_documents_sets_regions", {}, `Fetch fund documents presets for fund ${fundId}`)
      .then((response) => {
        setFundDocumentsOptions(response.data.response);
        setLoading(false);

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        toast.error('Document get failed', {
          duration: 3000, // Duration of the toast in milliseconds
          position: 'top-right', // Position of the toast on the screen
          // Other options...
        });
      });
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdownSelect = (value) => {
    setSelectedOption(value);
    setDropdownOpen(false);
  };


  const downloadDocumentsCSV = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      authenticatedAPI("GET_PRESET_DOCUMENTS_CSV", `/api/v1/oneconstellation/data/fund_documents/generatedocscsvfrompreset`, {
        method: "post",
        data: {
          regionPreset:selectedOption
        }
      }, `Download documents csv for fundId: ${selectedOption}`).then(response => {
        setLoading(false)
        const res = response.data.response;
        toast.success('Document download success', {
          duration: 3000, // Duration of the toast in milliseconds
          position: 'top-right', // Position of the toast on the screen
          // Other options...
        });
        resolve(res)
      }).catch((error) => {
        reject(error);
      })
    });
  }


  const handleAssignButtonClick = () => {
    setLoading(true);
    authenticatedAPI("ASSIGN_DOCUMENT_PRESET_TO_FUND", "/api/v1/oneconstellation/data/fund_documents_sets_regions", {
      method: "post",
      data: {
        fund_id: fundId,
        region: selectedOption
      }
    }, `Assign Document Preset to fund ${fundId}`)
      .then((response) => {
        setLoading(false);
        console.log(response);
        toast.success('Document set assigned successfully', {
          duration: 3000, // Duration of the toast in milliseconds
          position: 'top-right', // Position of the toast on the screen
          // Other options...
        });
      })
      .catch((error) => {
        setLoading(false);
        toast.error('Document set assignment failed', {
          duration: 3000,
          position: 'top-right',

        })
        console.error("Error fetching data:", error);

      });
    console.log("Selected option:", selectedOption);
  };

  return (

    <Card>
      <CardHeader className="text-center">
        <h5 className="title mb-0">User Documents Presets</h5>
      </CardHeader>
      <CardBody className="text-center">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
            <ThreeDots visible={true} height="40" width="40" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
          </div>
        ) : (
          <Card>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="mb-3">
              <DropdownToggle caret>
                {selectedOption ? selectedOption : "Select an option"}
              </DropdownToggle>
              <DropdownMenu className="w-100">
                {fundDocumentsOptions.map((option) => (
                  <DropdownItem key={option + "__key"} onClick={() => handleDropdownSelect(option.split(".")[0])}>
                    {option.split(".")[0]}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Row>
              <Col>
                <Button color="primary" className="w-100" onClick={handleAssignButtonClick} disabled={!selectedOption}>
                  Assign
                </Button>
              </Col>
              <Col>
                <CsvDownloader
                  filename={`preset-documents-${selectedOption}-${Date.now()}`}
                  extension=".csv"
                  columns={csvColumns}
                  separator=","
                  datas={downloadDocumentsCSV}
                >
                  <Button color="secondary" className="w-100" disabled={!selectedOption}>
                    Download CSV
                  </Button>
                </CsvDownloader>

              </Col>
            </Row>
          </Card>
        )}
      </CardBody>
      
    </Card>
  );
}
