import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter, selectFilter, numberFilter } from "react-bootstrap-table2-filter";
import { backgroundColors } from 'contexts/BackgroundColorContext';
import { Container } from 'reactstrap';
import { authenticatedAPI } from 'network/api';
import { ThreeDots } from "react-loader-spinner";

const entityNameSelectOptions = {
  "INDIVIDUAL": "INDIVIDUAL",
  "PRIVATE COMPANY": "PRIVATE COMPANY",
  "TRUST": "TRUST",
  "PARTNERSHIP": "PARTNERSHIP",
  "LLC": "LLC",
  "LLP": "LLP",
  "SUPERADMINFUND": "SUPERADMINFUND",
  "PUBLIC AND LISTED COMPANIES": "PUBLIC AND LISTED COMPANIES",
  
}

const columns = [
  {
    dataField: "id",
    text: "dId",
  },
  // {
  //   dataField: "key",
  //   text: "Key",
  // },
  {
    dataField: "name",
    text: "Name",
    filter: textFilter(),
  },
  // {
  //   dataField: "entity_name",
  //   text: "Entity",
  //   //formatter: cell => entityNameSelectOptions[cell],
  //   filter: selectFilter({
  //     options: entityNameSelectOptions
  //   })
  // },
  {
    dataField: "bucket_name",
    text: "Region",
  },
  {
    dataField: "doc_note",
    text: "Note",
  },
  {
    dataField: "has_document_number",
    text: "Doc Number",
  },
  {
    dataField: "has_issued_date",
    text: "Issued Date",
  },
  {
    dataField: "has_expiry_date",
    text: "Expiry Date",
  },
  {
    dataField: "has_amount",
    text: "Has Amount",
  },
  {
    dataField: "_id",
    text: "Actions",
    style: {
      width: '100px'
    },
    align: "center",
    formatter: (row, rowData) => {

      return (
        <div>
          {/* <a onClick={() => handleDocumentEdit(rowData)} className="cursor-pointer" style={{ marginRight: '10px' }} key={rowData.id + "edit"}>
            <FontAwesomeIcon icon={faEdit} />
          </a>

          {
            <>
              {rowData.category_id === 1 && <a onClick={() => handleShowModal(rowData)} className="cursor-pointer" style={{ marginRight: '10px' }} key={rowData.id + "show"}>
                <FontAwesomeIcon icon={faBookOpen} />
              </a>}

            </>
          }

          <a onClick={() => handleDeleteConfirmation(rowData)} className="cursor-pointer" style={{ marginRight: '10px' }} key={rowData.id + "delete"}>
            <FontAwesomeIcon icon={faTrash} />
          </a> */}
        </div>
      )
    }
  },

  // Add more columns as needed
];



const data = [

  // { id: 2, name: 'Item 2' },
  // Add more data rows as needed
];

const ChildDocumentModal = ({ modalLoadingChildDocuments, show, handleClose, childDocumentData, parentDocumentData }) => {


  console.log("parentDocumentData",parentDocumentData)


  return (

    <Container fluid className="child-doc-non-scrollable-container">
      <Modal show={show} onHide={handleClose} className="modal-dialog-centered custom-modal-wide">


        <Modal.Header closeButton className="custom-modal-header child-doc-modal">
          <Modal.Title className='child-doc-modal-title' style={{color: "#fff"}}>{parentDocumentData.entity_name} - {parentDocumentData.document_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="child-doc-modal child-doc-modal-body">
          {(!modalLoadingChildDocuments ? (
          <BootstrapTable
            keyField="id"
            data={childDocumentData}
            columns={columns}
            pagination={paginationFactory({ pageSizeOptions: [50, 100, 200, 500, 1000] })}
            bootstrap4={true}
            striped
            hover
            noDataIndication="No data available"
            defaultSorted={[{ dataField: "id", order: "asc" }]}
            filter={filterFactory()} // Enable table filtering
            wrapperClasses="table-responsive child-doc-modal-table-wrapper" // Make table responsive with custom styles
          />
          ):(<div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
            <ThreeDots visible={true} height="40" width="40" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
          </div>)
          )}
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer child-doc-modal">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
    </Container>
  );
};

export default ChildDocumentModal;
