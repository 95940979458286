import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { authenticatedAPI } from 'network/api';

const LogoutProcess = () => {
  const [logoutTime, setLogoutTime] = useState(3);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    
    const token = localStorage.getItem("Authorization")
    const userIdentifier = localStorage.getItem("userIdentifier")

    let interval = setInterval(() => {
      setLogoutTime(prevLogoutTime => prevLogoutTime - 1);
    }, 1000);
    
    authenticatedAPI("USER_LOGOUT","/api/v1/users/authentication/logout",{
        method: "post",
        headers:{
          Authorization: `${token}`
        }
    }, `Attempting to logout user ${userIdentifier.appUserEmail}`)
    

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (logoutTime === 0) {
      localStorage.removeItem("Authorization");
      localStorage.removeItem("permissions");
      localStorage.removeItem("userIdentifier");
      setRedirect(true);
    }
  }, [logoutTime]);

  // Check if user is authenticated
  
  const isAuthenticated = localStorage.getItem('Authorization');

  if (!isAuthenticated) {
    return <Navigate to="/authentication/login" />;
  }

  if (redirect) {
    return <Navigate to="/authentication/login" />;
  }

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Card className="p-4" style={{ minWidth: '300px', maxWidth: '400px' }}>
        <CardBody className="text-center">
          <h1>Authentication</h1>
          <p>You're logged out from your session, redirecting to login in {logoutTime} seconds...</p>
        </CardBody>
      </Card>
    </div>
  );
};

export default LogoutProcess;
