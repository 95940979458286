import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import DocusignDocumentsPresetsBox from "./DocusignDocumentsPresetsBox";
import UserDocumentsPresetBox from "./UserDocumentsPresetBox";

export default function AssignDocumentsSets({ fundId }) {

  

  useEffect(() => {
    
  }, []);


  
  return (
    <div className="content mt-3">
      <div className="row">
        <div className="col-md-6">
          < UserDocumentsPresetBox fundId={fundId} />
        </div>
        <div className="col-md-6">
              <DocusignDocumentsPresetsBox fundId={fundId} />
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  );
}
