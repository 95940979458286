import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter, selectFilter, dateFilter, Comparator } from "react-bootstrap-table2-filter";
import toast, { Toaster } from 'react-hot-toast';
import { Card, CardHeader, CardBody, Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Input, CardText, InputGroup, Label, FormGroup, ButtonGroup } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faBookOpen, faTrash, faGear } from '@fortawesome/free-solid-svg-icons'
import ChildDocumentModal from "./ChildDocumentModal";
import { authenticatedAPI } from "../../../network/api";
import CsvDownloader from 'react-csv-downloader';
import ChildDocusignConfigurationModal from "./ChildDocusignConfigurationModal.js";

export default function FundsDocuments({ fundId, setHasAssignedDocuments, setFundDocumentCurrentTab }) {


  const [fundDocumentData, setfundDocumentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [rowData, setRowData] = useState({});
  const [selectedDocumentName, setSelectedDocumentName] = useState("");
  const [selectedVisibility, setSelectedVisibility] = useState("");
  const [selectedIsMandatory, setSelectedIsMandatory] = useState("");
  const [deleteModal, setDeleteModal] = useState(false); // State for delete confirmation modal
  const [deleteTarget, setDeleteTarget] = useState(null); // State to store the target document for deletion
  const [showModal, setShowModal] = useState(false);
  const [showDocuSignModal, setDocuSignModal] = useState(false);
  const [childDocumentData, setChildDocumentData] = useState([]);
  const [parentDocumentData, setParentDocumentData] = useState(null);

  const [modalLoadingChildDocuments, setModalLoadingChildDocuments] = useState(true);
  const [fundCSVDocuments, setFundCSVDocuments] = useState([]);
  // show={showChildDocusignModal} handleClose={handleCloseChildDocusignModal} childDocusignData={childDocusignData} modalLoadingChildDocusign={modalLoadingChildDocusign} parentDocumentData={parentDocumentData}
  const handleShowModal = (rdata) => {
    console.log("---------------------------------------")
    console.log("rowData", rdata)
    setParentDocumentData(rdata);
    setModalLoadingChildDocuments(true);
    setShowModal(true)
    authenticatedAPI("GET_DOCUMENT_CHILD_DOCUMENTS",`/api/v1/oneconstellation/data/fund_documents/childdocument?parentDocumentId=${rdata.document_type_id}`, {
      method: "get"
    }, `Fetch child documents for ${rdata.document_type_id} | ${rdata.document_name} | (${rdata.entity_name}) for fundId: ${fundId}`).then((response) => {

      setModalLoadingChildDocuments(false);
      setChildDocumentData(response.data.response)
      console.log(response.data)

    })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowModal(false)
        setModalLoadingChildDocuments(true);

        // Set loading to false in case of error
      })

  }


  const handleShowModalDocusign = (rdata) => {
    console.log("---------------------------------------")
    console.log("rowData", rdata)
    setParentDocumentData(rdata);
    setDocuSignModal(true)
  }

  const handleCloseModal = () => setShowModal(false);

  const handleCloseModalDocusign = () => setDocuSignModal(false);



  const toggle = () => setModal(!modal);

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const handleDeleteConfirmation = (rowData) => {
    setSelectedDocumentName(`${rowData.document_name} (${rowData.entity_name})`)
    setDeleteTarget(rowData);
    toggleDeleteModal();
  };


  const downloadDocumentsCSV = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      authenticatedAPI("GET_FUND_DOCUMENTS_CSV",`/api/v1/oneconstellation/data/fund_documents/generatedocscsv?fundId=${fundId}`, {
        method: "get",
      }, `Download documents csv for fundId: ${fundId}`).then(response => {
        setLoading(false)
        const res = response.data.response;
        toast.success('Document download success', {
          duration: 3000, // Duration of the toast in milliseconds
          position: 'top-right', // Position of the toast on the screen
          // Other options...
        });
        resolve(res)
      }).catch((error) => {
        reject(error);
      })
    });


  }

  useEffect(() => {


    if(fundId)
    {
      authenticatedAPI("GET_FUND_DOCUMENTS",`/api/v1/oneconstellation/data/fund_documents?fundId=${fundId}`, {
        method: "get"
      }, `Fetch fund documents for fundId: ${fundId}`).then((response) => {
  
        for (let q of response.data.response) {
          if (q.category_id == 1) {
            setHasAssignedDocuments(true)
            break;
          }
        }
  
        setfundDocumentData(response.data.response)
        setLoading(false)
      })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false)
          // Set loading to false in case of error
        });
    }
   
  },[]);

  const handleConfirmDelete = () => {
    setLoading(true);
    toggleDeleteModal();
    authenticatedAPI("DELETE_FUND_DOCUMENT",`/api/v1/oneconstellation/data/fund_documents?fundId=${fundId}`, {
      method: "delete",
      data: {
        fundId: fundId,
        documentId: deleteTarget.id
      },
    }, `Delete fund document for fundId: ${fundId} and documentId: ${deleteTarget.id}`).then((response) => {
      let updatedFundDocumentData = fundDocumentData.filter(doc => doc.id !== deleteTarget.id);
      setfundDocumentData(updatedFundDocumentData);
      setDeleteTarget(null);
      setLoading(false);
      toggleDeleteModal(); // Close the delete confirmation modal after deletion
      toast.success('Document deleted successfully', {
        duration: 3000, // Duration of the toast in milliseconds
        position: 'top-right', // Position of the toast on the screen
        // Other options...
      });
    })
      .catch((error) => {
        console.error("Error deleting document:", error);
        setLoading(false);
        toast.error('Document deletion failed', {
          duration: 3000, // Duration of the toast in milliseconds
          position: 'top-right', // Position of the toast on the screen
          // Other options...
        });

      });
  };


  const handleDocumentEdit = (rowData) => {
    setSelectedVisibility(rowData.for);
    setSelectedIsMandatory(rowData.is_required);
    setRowData(rowData);
    setModal(true);
  };


  const handleSaveData = (fdocid, visibility, isMandatory) => {
    setModalLoading(true);
    console.log({ fdocid, visibility, isMandatory })
    authenticatedAPI("UPDATE_FUND_DOCUMENT_PROPS",`/api/v1/oneconstellation/data/fund_documents?fundId=${fundId}`, {
      method: "post",
      data: {
        documentId: fdocid,
        _for: visibility,
        is_required: isMandatory
      },
    }, `Update fund document for fundId: ${fundId} and documentId: ${fdocid}`).then((response) => {

      let copyOffundDocumentData = [...fundDocumentData];
      for (let i of copyOffundDocumentData) {
        if (i.id === fdocid) {
          i.for = visibility;
          i.is_required = isMandatory;
          break;
        }
      }
      setfundDocumentData(copyOffundDocumentData);
      setModalLoading(false);
      setModal(false);

    })
      .catch((error) => {
        toast.error('Updating Fund Documents Failed', {
          duration: 3000, // Duration of the toast in milliseconds
          position: 'top-right', // Position of the toast on the screen
          // Other options...
        });
        console.error("Error fetching data:", error);
        setLoading(false)
        // Set loading to false in case of error
      });



  }

  const csvColumns = [
    {
      id: "TAG",
      displayName: 'TAG'
    },
    {
      id: "NAME",
      displayName: 'NAME'
    },
    {
      id: "ENTITY",
      displayName: 'ENTITY'
    },
    {
      id: "CATEGORY",
      displayName: 'CATEGORY'
    },
    {
      id: "IS_REQUIRED",
      displayName: 'IS_REQUIRED'
    },
    {
      id: "DOCUMENT_NUMBER",
      displayName: 'DOCUMENT_NUMBER'
    },
    {
      id: "ISSUED_DATE",
      displayName: 'ISSUED_DATE'
    },
    {
      id: "EXPIRY_DATE",
      displayName: 'EXPIRY_DATE'
    },
    {
      id: "HAS_AMOUNT",
      displayName: 'HAS_AMOUNT'
    },
    {
      id: "TYPE_ID",
      displayName: 'TYPE_ID'
    },
  ]
  const visibilityRoles = {
    "all": "all",
    "crp": "crp",
    "root": "root"
  }

  const isMandatoryDropDown = {
    "true": "true",
    "false": "false",
  }
  const entityNameSelectOptions = {
    "INDIVIDUAL": "INDIVIDUAL",
    "PRIVATE COMPANY": "PRIVATE COMPANY",
    "TRUST": "TRUST",
    "PARTNERSHIP": "PARTNERSHIP",
    "LLC": "LLC",
    "LLP": "LLP",
    "SUPERADMINFUND": "SUPERADMINFUND",
    "PUBLIC AND LISTED COMPANIES": "PUBLIC AND LISTED COMPANIES",
    "REGULATED VEHICLE INVESTMENT":"REGULATED VEHICLE INVESTMENT",
    "UNREGULATED VEHICLE INVESTMENT":"UNREGULATED VEHICLE INVESTMENT"
  }
  const documentCategoryType = {
    "Document": "Document",
    "DocuSign": "DocuSign"
  }
  const columns = [
    {
      dataField: "id",
      text: "FDocId",
    },
    {
      dataField: "document_type_id",
      text: "TypeId",
    },
    {
      dataField: "document_name",
      text: "Document Name",
      filter: textFilter(),
    },
    {
      dataField: "document_category",
      text: "Document Type",
      filter: selectFilter({
        options: documentCategoryType
      }),
    },
    {
      dataField: "entity_name",
      text: "Entity",
      //formatter: cell => entityNameSelectOptions[cell],
      filter: selectFilter({
        options: entityNameSelectOptions
      })
    },
    {
      dataField: "for",
      text: "visibility",
      filter: selectFilter({
        options: visibilityRoles
      }),
    },
    {
      dataField: "is_required",
      text: "Is Required",
      filter: selectFilter(
        {
          options: isMandatoryDropDown
        }
      ),
    },
    {
      dataField: "created_at",
      text: "Created at",
      filter: dateFilter({
        //comparatorStyle: { backgroundColor: 'antiquewhite' },
        withoutEmptyComparatorOption: true,
        comparators: [],
        type: "date"
      }),
      formatter: (row, rowData) => {
        return rowData.created_at ? new Date(rowData.created_at).toLocaleDateString() : "";
      }
    },
    {
      dataField: "_id",
      text: "Actions",
      style: {
        width: '100px'
      },
      align: "center",
      formatter: (row, rowData) => {

        return (
          <div>
            <a onClick={() => handleDocumentEdit(rowData)} className="cursor-pointer" style={{ marginRight: '10px' }} key={rowData.id + "edit"}>
              <FontAwesomeIcon icon={faEdit} />
            </a>

            {
              <>
                {rowData.category_id === 1 && <a onClick={() => handleShowModal(rowData)} className="cursor-pointer" style={{ marginRight: '10px' }} key={rowData.id + "show"}>
                  <FontAwesomeIcon icon={faBookOpen} />
                </a>}
                {rowData.document_category === "DocuSign" && <a onClick={() => handleShowModalDocusign(rowData)} className="cursor-pointer" style={{ marginRight: '10px' }} key={rowData.id + "show"}>
                  <FontAwesomeIcon icon={faGear} />
                </a>}


              </>
            }

            <a onClick={() => handleDeleteConfirmation(rowData)} className="cursor-pointer" style={{ marginRight: '10px' }} key={rowData.id + "delete"}>
              <FontAwesomeIcon icon={faTrash} />
            </a>
          </div>
        )
      }
    },
  ];
  return (
    <div className="content">

      {fundDocumentData &&


        <Container fluid>

          {modal && rowData && (
            <Modal isOpen={modal} toggle={toggle} key={rowData.id} size="sm" fade={true} className="bg-transparent" >

              <ModalBody style={{ backgroundColor: "#1e1e2f" }}>
                {modalLoading ? (
                  // Center the loading spinner within the modal body
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                    <ThreeDots visible={true} height="40" width="40" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
                  </div>
                ) : (
                  <Card>
                    <CardHeader>
                      <h5 className="title">Fund Documents</h5>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <Label for="visibilityDropdown">Visibility:</Label>
                        <Input
                          type="select"
                          name="visibility"
                          id="visibilityDropdown"
                          value={selectedVisibility}  // Use value instead of defaultValue
                          onChange={(e) => setSelectedVisibility(e.target.value)}
                        >
                          {Object.keys(visibilityRoles).map((key) => (
                            <option key={key} value={key}>
                              {visibilityRoles[key]}
                            </option>
                          ))}
                        </Input>
                      </InputGroup>
                      <InputGroup>
                        <Label for="mandatoryDropdown">Is Required:</Label>
                        <Input type="select" name="mandatory" id="mandatoryDropdown" value={selectedIsMandatory} onChange={(e) => setSelectedIsMandatory(e.target.value)}>
                          {Object.keys(isMandatoryDropDown).map((key) => (
                            <option key={key} value={key}>
                              {isMandatoryDropDown[key]}
                            </option>

                          ))}
                        </Input>
                      </InputGroup>

                    </CardBody>
                  </Card>
                )}
                {!modalLoading && (

                  <ButtonGroup className="d-flex justify-content-right">
                    <Button color="secondary" onClick={toggle}>
                      Cancel
                    </Button>
                    <Button color="primary" onClick={() => handleSaveData(rowData.id, selectedVisibility, selectedIsMandatory)}>
                      Save
                    </Button>
                  </ButtonGroup>
                )}
              </ModalBody>
            </Modal>
          )}
          <Modal isOpen={deleteModal} toggle={toggleDeleteModal} size="sm">
            <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
            <ModalBody>
              Are you sure you want to delete <div className="text-center"><br /> <b> {selectedDocumentName} </b><br /></div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
              <Button color="danger" onClick={handleConfirmDelete}>Delete</Button>
            </ModalFooter>
          </Modal>
          {parentDocumentData && (
            <ChildDocusignConfigurationModal show={showDocuSignModal} handleClose={handleCloseModalDocusign} parentDocumentData={parentDocumentData}/> )
          }
           {parentDocumentData && (
          <ChildDocumentModal show={showModal} handleClose={handleCloseModal} childDocumentData={childDocumentData} modalLoadingChildDocuments={modalLoadingChildDocuments} parentDocumentData={parentDocumentData} />
           )}
          <Card>
            <CardHeader className="fund-documents-title-container">
              <h5 className="fund-documents-title">Fund Documents</h5>
              <CsvDownloader
                filename={`fund-documents-${fundId}-${Date.now()}`}
                extension=".csv"
                columns={csvColumns}
                separator=","
                datas={downloadDocumentsCSV}
              >
                <i className="fa fa-download fund-documents-download-icon"> csv</i>
              </CsvDownloader>
            </CardHeader>
            <CardBody>
              {loading ? (
                // Display loading indicator
                <div className="d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                  <ThreeDots visible={true} height="40" width="40" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
                </div>
              ) : fundDocumentData && fundDocumentData.length > 0 ? (
                // Display table if data is available
                <BootstrapTable
                  keyField="id"
                  data={fundDocumentData}
                  columns={columns}
                  pagination={paginationFactory({ pageSizeOptions: [50, 100, 200, 500, 1000] })}
                  bootstrap4={true}
                  striped
                  hover
                  condensed
                  noDataIndication="No data available"
                  defaultSorted={[{ dataField: "id", order: "asc" }]}
                  filter={filterFactory()} // Enable table filtering
                />
              ) : (
                // Display message if no data is available
                <div>No data available</div>
              )}
            </CardBody>
          </Card>
        </Container>
      }
            <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  );
}