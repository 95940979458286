import React, { useEffect, useState } from "react";

import toast, { Toaster } from 'react-hot-toast';

export default function FundsDocuments({ fundId, setHasAssignedDocuments, setFundDocumentCurrentTab }) {


  React.useEffect(() => {

  }

  , []);

  return (
    <div className="content">
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  );
}